@import 'src/scss/base';

.tags-list {
  width: 100%;
  margin-bottom: $spacing-4;
  display: flex;
  align-items: center;
}

.tags-list__tag {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  background-color: $color-grey-light;
  border-radius: $border-radius-32;
  max-width: calc(100% - 30px);
  margin-right: $spacing-8;

  &:last-child {
    margin-right: 0;
  }
}

.tags-list__success-icon {
  margin-left: $spacing-4;
  flex-shrink: 0;
}

.tags-list__tag-name {
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: $spacing-4 $spacing-8 $spacing-4 0;
  font-size: 12px;
  line-height: normal;
}

.tags-list__additional {
  background-color: $color-dark-grey-3;
  padding: 0 $spacing-8;
  height: 24px;
  border-radius: $border-radius-32;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include md {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.tags-list__additional.tags-list__additional--visible {
  opacity: 1;
}

.tags-list__number {
  font-weight: 400;
  line-height: normal;
}

.tags-list__tooltip {
  position: absolute;
  top: 130%;
  right: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.tags-list__additional.tags-list__additional--visible .tags-list__tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.tags-list__tooltip-tag {
  background-color: $color-dark-grey-3;
  color: $color-white;
  margin-bottom: $spacing-4;
  max-width: none;
  width: 100%;
}

.tags-list__tooltip-tag:last-child {
  margin: 0;
}
