@import 'src/scss/base';

.info-banner {
  width: 100%;
  background-color: $color-warning-surface;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 50px;
  @include font-aribau;
  font-size: px2rem(14);
  color: $color-blacky;
  font-weight: 500;

  @include lg {
    width: 100%;
    max-width: px2rem(920);
    margin: 0 auto;
    margin-bottom: 50px;
  }
}
