@import 'src/scss/base';

.learn-more-page-generator__title {
  margin-top: $spacing-24;
  padding: 0 $spacing-24;

  @include md {
    margin-top: $spacing-32;
  }

  @include lg {
    margin-top: $spacing-40;
    padding: 0;
  }
}

.learn-more-page-generator__bannerWithTextBox {
  margin-top: $spacing-24;

  @include md {
    padding: 0 $spacing-24;
    margin-top: $spacing-32;
  }

  @include lg {
    margin-top: $spacing-40;
    padding: 0;
  }
}

.learn-more-page-generator__bannerWithList,
.learn-more-page-generator__bannerWithTextAndDescription,
.learn-more-page-generator__bannerWithListAndProductCard {
  margin-top: $spacing-16;
  padding: 0 $spacing-24;

  @include md {
    margin-top: $spacing-24;
  }

  @include lg {
    padding: 0;
  }
}
