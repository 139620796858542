@import 'src/scss/base';

.my-health-container {
  width: 100%;
  background-color: $color-grey-light;
  padding-bottom: $spacing-80;

  @include lg {
    padding-bottom: 0;
  }
}

.my-health-container__loader {
  width: 100%;
  height: 100vh;
  background: $color-grey-light;
  position: relative;
}

.my-health-container__loader::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: $color-secondary;
  border-radius: 50%;
  content: '';
  height: 40px;
  width: 40px;
  position: absolute;
  top: calc(50% - 82px);
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;

  @include lg {
    top: calc(50% - 98px);
  }
}
