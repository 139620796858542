@import 'src/scss/base';

.wrapper {
  @include button-default;
  color: $color-blacky;
  min-width: 120px;
  width: 100%;
}

.content {
  background-color: $color-white;
  border-radius: $spacing-16;
  padding: $spacing-24;
  position: relative;

  & svg {
    width: $spacing-24;
    height: $spacing-24;
  }
}

.title {
  width: $spacing-64;
  margin: $spacing-8 0 $spacing-16 0;
}

.line {
  width: 34px;
  height: 6px;
  border-radius: $spacing-4;
}

.cardShadow {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  bottom: 15px;
  width: 30%;
  height: $spacing-80;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
}
