@import 'src/scss/base';

.test-results-navigation {
  height: 36px;
  margin-left: $spacing-24;
  margin-right: $spacing-24;
  margin-bottom: $spacing-32;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;

  @include md {
    margin-left: 0;
    margin-right: 0;
  }

  @include lg {
    margin: 0;
    height: auto;
  }
}

.test-results-navigation__top-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  display: none;
  background: #f1f1f1f6;
  z-index: -1;
}

.test-results-navigation__fixed {
  position: fixed;
  top: 82px;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px $spacing-24 0 $spacing-24;
  opacity: 1;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;

  & .test-results-navigation__top-overlay {
    display: block;
  }

  @include md {
    max-width: 745px;
    margin: 0 auto;
    padding: 10px $spacing-40 0 $spacing-40;
  }
}

.test-results-navigation__content {
  color: $color-blacky;
  background: $color-white;
  border-radius: $border-radius-24;
  box-shadow: 4px 6px 24px 2px #0000004d;

  @include lg {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}

.test-results-navigation__links-wrapper {
  color: $color-blacky;
  overflow: scroll;
  display: flex;
  gap: $spacing-16;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 $spacing-24;

  @include lg {
    margin-left: $spacing-16;
    padding: 0;
    flex-direction: column;
    gap: $spacing-4;
  }
}

.test-results-navigation__links-wrapper::-webkit-scrollbar {
  display: none;
}

.test-results-navigation__title {
  display: none;
  text-transform: uppercase;

  @include lg {
    margin-bottom: $spacing-8;
    display: block;
  }
}
