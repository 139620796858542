@import 'src/scss/base';

.wrapper {
  z-index: 2;
  position: sticky;
  top: 0;
  background: $color-grey-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-16;

  @include md {
    padding: $spacing-24 $spacing-16;
  }

  @include lg {
    padding: $spacing-32;
  }

  &.scrolled {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.backIcon {
  @include button-default;
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid $color-white;
  border-radius: $spacing-8;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
  position: relative;
}

.userWrapper {
  @include button-default;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $spacing-8;
  border: 1px solid $color-white;
  border-radius: $spacing-8;
  margin: 0;
}

.user {
  @include button-default;
  width: $spacing-16;
  height: $spacing-16;
}

.userDropdown {
  @include button-default;
  position: absolute;
  bottom: -37px;
  right: 0;
  z-index: 1;
  padding: $spacing-8;
  border-radius: $spacing-8;
  background-color: $color-white;
  box-shadow: 0 8px $spacing-16 0 rgba(0, 0, 0, 0.2);
}

.userDropdownItem {
  color: $color-blacky;

  &:hover {
    opacity: 0.5;
  }
}
