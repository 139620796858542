@import 'src/scss/base';

.my-health-health-knowledge {
  background-image: url('../../../assets/images/girl-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin-top: $spacing-72;

  @include md {
    background-image: url('../../../assets/images/girl-tablet.png');
    height: 100%;
  }

  @media screen and (min-width: 1440px) {
    background-image: url('../../../assets/images/girl-desktop.png');
    margin-top: 0;
    background-position: center;
    height: 100%;
  }
}

.my-health-health-knowledge__content-wrapper {
  padding: $spacing-24 $spacing-24;

  @include md {
    padding: $spacing-24 $spacing-40 $spacing-40 $spacing-40;
  }

  @include lg {
    width: 740px;
    margin: 0 auto;
    padding: $spacing-24 0 $spacing-40 0;
  }

  @media screen and (min-width: 1440px) {
    width: auto;
    padding: $spacing-40 $spacing-32;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.my-health-health-knowledge__divider,
.my-health-health-knowledge__divider-line {
  color: $color-white;
}

.my-health-health-knowledge__content {
  @include button-default;
}

.my-health-health-knowledge__card-background {
  background: rgba(52, 52, 52, 0.47);
  backdrop-filter: blur(2px);
}
