@import 'src/scss/base';

.checkup-banner {
  padding: $spacing-16 $spacing-24;
  border-radius: $border-radius;
  background: $color-red-gradient;
  display: flex;
  align-items: flex-start;
  gap: $spacing-16;
  margin-bottom: $spacing-64;
}

.checkup-banner__icon {
  margin-top: 2px;
  width: 18px;
  height: 16px;
}

.checkup-banner__text {
  color: $color-white;

  @include md {
    font-size: 16px;
  }
}
