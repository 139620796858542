@import 'src/scss/base';

.wrapper {
  padding: $spacing-32 $spacing-24 $spacing-24 $spacing-24;
  background: $color-additional-2;
  color: $color-white;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  border-radius: $border-radius;
}

.title {
  @include md {
    font-size: 24px;
    line-height: 28.8px;
  }

  @include lg {
    font-size: 24px;
    line-height: 28.8px;
  }
}
