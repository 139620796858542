@import 'src/scss/base';

.bannerWithTextAndButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $color-white;
  padding: $spacing-24;
  width: 100%;
  height: 100%;

  @include md {
    border-radius: $border-radius;
    overflow: hidden;
  }

  @include lg {
    max-height: 800px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textWrapper {
  z-index: 1;
}

.title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: $spacing-16;
}

.text {
  font-size: 16px;
  opacity: 0.85;
}

.buttonWrapper {
  text-decoration: none;
  z-index: 1;
  padding: $spacing-16 $spacing-16 $spacing-16 $spacing-24;
  background-color: $color-white;
  color: $color-blacky;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-8;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.buttonText {
  width: 60%;

  @include md {
    width: 100%;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: $border-radius;
}
