@import 'src/scss/base';

.button {
  @include small-button-text;
  border: 1px solid #f2f2f2;
  border-radius: 32px;
  padding: 0 $spacing-16 0 12px;
  height: 38px;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  background-color: $color-white;
  color: $color-blacky;
  cursor: pointer;

  &.buttonLoading {
    background-color: $color-grey-light;
  }

  :global(.no-touchevents) &:hover {
    opacity: 0.8;
  }

  &.buttonCompleted {
    background-color: transparent;
    border: 1px solid $color-white;
  }

  &.buttonError {
    border: 1px solid $color-primary;
  }
}

.loadingCircle {
  width: 24px;
  height: 24px;
  border: 3px solid $color-white;
  border-bottom-color: $color-grey-light;
}

.iconWrapper {
  display: flex;
  align-items: center;
}
