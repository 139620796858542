@import 'src/scss/base';

.wrapper {
  position: relative;
  background-color: $color-white;
  color: $color-blacky;
  padding: $spacing-24;
  border-radius: $border-radius;
  box-shadow: $card-box-shadow;

  &.wrapperCompleted {
    background: $color-grey-card;
    box-shadow: none;

    .subtitleWrapper {
      background-color: $color-white;
    }
  }

  @include md {
    display: flex;
  }
}

.imageAndTextWrapper {
  display: flex;
  gap: $spacing-16;
  margin-bottom: $spacing-16;

  @include md {
    margin: 0;
    margin-right: $spacing-16;
  }
}

.image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  flex-shrink: 0;

  @include md {
    width: 60px;
    height: 60px;
  }
}

.textWrapperMobile {
  @include md {
    display: none;
  }
}

.textWrapperDesktop {
  display: none;

  @include md {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-8;
  }
}

.title {
  @include md {
    font-size: 20px;
    font-weight: 500;
  }
}

.subtitleWrapper {
  background-color: $color-grey-light;
  border-radius: $border-radius-32;
  display: flex;
  align-items: center;
  gap: $spacing-4;
  width: fit-content;
  padding: $spacing-4 $spacing-8;
  margin-top: $spacing-8;

  @include md {
    margin-top: 0;
  }
}

.subtitle {
  line-height: normal;
  font-size: 12px;

  @include md {
    font-weight: 400;
  }
}

.description {
  margin-bottom: $spacing-24;
  font-size: 14px;
  color: $color-dark-grey;

  @include md {
    width: 80%;
    font-size: 16px;
    color: $color-grey-darker;
  }
}

.buttonsWrapper {
  display: flex;
  gap: $spacing-16;
}

.learnMoreLinkWhenCompleted {
  span {
    background-color: $color-grey-card;
  }
}

.rightSideWrapper {
  flex: 1;
}

.popUpImage {
  position: absolute;
  right: 8px;
  bottom: -16px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  pointer-events: none;
}

.showPopUp {
  opacity: 1;
  transform: scale(1);
}
