@import 'src/scss/base';

.wrapper {
  padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24;
  width: 100%;
  min-height: 480px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include md {
    border-radius: $border-radius;
  }
}

.textBox {
  padding: $spacing-16;
  border-radius: $border-radius-24;
  background: $color-semi-transparent-gradient-white;
  backdrop-filter: blur(10px);
  white-space: pre-line;
  color: $color-white;
}
