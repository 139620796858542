@import 'src/scss/base';

.wrapper {
  position: relative;
}

.buttonWrapper {
  margin-top: $spacing-16;

  @include md {
    margin-top: $spacing-16;
  }
}
