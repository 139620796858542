@import 'src/scss/base';

.my-health {
  color: $color-blacky;
  position: relative;
  width: 100%;
  display: flex;
  z-index: 1;
}

.my-health__left-block {
  width: 100%;

  @media screen and (min-width: 1440px) {
    padding-bottom: $spacing-56;
    width: 70%;
  }
}

.my-health__right-block {
  display: none;

  @media screen and (min-width: 1440px) {
    display: block;
    width: 30%;
    position: fixed;
    right: 0;
    height: calc(100vh - 100px);
    top: 100px;
  }
}

.my-health__header {
  width: 100%;
  padding: 0 $spacing-24;
  margin: 0 auto;
  margin-top: $spacing-24;
  margin-bottom: $spacing-24;

  @include md {
    padding: 0 $spacing-40;
    margin-top: $spacing-32;
  }

  @include lg {
    padding: 0;
    margin-top: $spacing-64;
    width: 740px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: $spacing-64;
  }
}

.my-health__employee-health-program {
  margin-top: $spacing-72;
  padding: 0 $spacing-24;

  @include md {
    padding: 0 $spacing-40;
  }

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
    margin-top: $spacing-72;
  }
}

.my-health__quick-actions {
  margin-top: $spacing-72;
  padding: 0 $spacing-24;

  @include md {
    padding: 0 $spacing-40;
  }

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
    margin-top: $spacing-72;
  }
}

.my-health__your-products {
  margin-top: $spacing-72;
  padding: 0 $spacing-24;

  @include md {
    padding: 0 $spacing-40;
  }

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
    margin-top: $spacing-72;
  }
}

.my-health__health-knowledge-mobile {
  @media screen and (min-width: 1440px) {
    display: none;
  }
}

.my-health__carousel {
  @include lg {
    max-width: 740px;
    margin: 0 auto;
    overflow: visible;
    width: 740px;
    margin: 0 auto;
  }
}
