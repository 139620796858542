@import 'src/scss/base';

.wrapper {
  padding: $spacing-32 $spacing-24 $spacing-24 $spacing-24;
  background: $color-white;
  color: $color-blacky;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  border-radius: $border-radius;
  box-shadow: $card-box-shadow;

  @include md {
    gap: $spacing-24;
  }
}

.title {
  font-size: px2rem(24);
  line-height: 28px;
}

.description {
  margin-top: $spacing-8;
}

.list {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.link {
  text-decoration: none;
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  cursor: pointer;
}
