@import 'src/scss/base';

.profile-button {
  position: relative;
  display: flex;
}

.profile-button__button {
  width: px2rem(60);
  height: px2rem(36);
  border-radius: px2rem(25);
  border: solid 1px $color-purple;
  background: none;
  z-index: 2;
  position: relative;
  background: $color-white;
  padding: 0 px2rem(10);

  @include lg {
    cursor: pointer;
    min-width: px2rem(176);
    max-width: px2rem(240);
    width: auto;
    height: px2rem(50);
    padding: px2rem(6) px2rem(16) px2rem(6);
  }
}

.profile-button__content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @include lg {
    justify-content: space-between;
  }
}

.profile-button__button-icon {
  width: px2rem(18);
  height: px2rem(18);
  display: block;
  margin-right: px2rem(8);

  svg {
    width: 100%;
    fill: $color-purple;
  }
}

.profile-button__arrow {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $color-purple;
  height: 0;
  width: 0;
  display: block;
  margin-top: px2rem(2);
}

.profile-button__text-wrapper {
  display: none;

  @include lg {
    display: block;
    flex: 1;
    text-align: left;
    color: $color-purple;
    margin-right: px2rem(10);
  }
}

.profile-button__text {
  @include font-aribau-bold;
  font-size: px2rem(14);
  letter-spacing: normal;
  display: block;
}

.profile-button__name {
  @include font-aribau;
  font-size: px2rem(12);
  display: block;

  &--blurred {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.profile-button__name-mobile {
  @include font-aribau;
  font-size: px2rem(12);
  display: block;
  color: $color-purple;
  padding: px2rem(5);

  @include lg {
    display: none;
  }
}

.profile-button__arrow-down {
  transition: all 0.3s ease;
}

.profile-button__arrow-up {
  transform: rotate(-180deg);
  transition: all 0.3s ease;
}

.profile-buttom__container {
  position: absolute;
  left: 0;
  top: px2rem(40);
  min-width: px2rem(150);
  padding: px2rem(10) px2rem(30) px2rem(10) px2rem(16);
  border-radius: px2rem(5);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $color-white;
  z-index: 1;

  @include lg {
    width: 100%;
    padding: px2rem(40) px2rem(60) px2rem(10) px2rem(16);
    top: px2rem(25);
  }
}

.profile-buttom__container-button {
  @include font-aribau-bold;
  width: px2rem(95);
  height: px2rem(35);
  font-size: px2rem(12);
  text-align: left;
  color: $color-purple;
  background: $color-white;
  border: 0;
  border-top: solid 1px $color-light-grey;
  padding: 0 px2rem(6);

  @include lg {
    cursor: pointer;
  }
}
