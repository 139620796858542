@import 'src/scss/base';

.wrapper {
  background-color: $color-white;
  border-radius: $border-radius;
  padding: $spacing-32 $spacing-24 $spacing-24 $spacing-24;
  box-shadow: $card-box-shadow;
}

.list {
  padding: 0;
  margin-bottom: $spacing-16;
  box-shadow: none;
}

.product {
  margin-top: 0;
  padding-top: $spacing-24;
  border-top: 1px dashed $color-grey-light;
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  @include md {
    border-bottom: 1px dashed $color-grey-light;
  }
}
