@import 'src/scss/base';

.wrapper {
  overflow-y: scroll;
  overscroll-behavior: none;
  height: 100%;
}

.contentWrapper {
  @include md {
    display: flex;
    justify-content: center;
  }
}

.content {
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;

  @include md {
    padding: 0 $spacing-16 200px $spacing-16;
    width: 745px;
  }

  @include lg {
    padding: 0;
    padding-bottom: 200px;
    border-right: 1px solid $color-grey;
  }
}

.testResultsHeader {
  padding: 0 $spacing-24;
  color: $color-blacky;
  margin-top: $spacing-4;
  margin-bottom: $spacing-4;

  @include md {
    padding: 0;
    width: 550px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: $spacing-56;
  }
}

.navigationMobileWrapper {
  display: block;

  @include md {
    padding: 0 $spacing-24;
  }

  @include lg {
    display: none !important;
  }
}

.summary {
  padding: 0 $spacing-24;

  @include lg {
    padding: 0;
  }
}

.doctorReport {
  padding: 0 $spacing-24;

  @include lg {
    padding: 0;
  }
}

.reportBox {
  box-shadow: $card-box-shadow;
  margin-top: $spacing-24;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.labResultsWrapper {
  padding: 0 $spacing-24;
  margin-top: $spacing-32;

  @include md {
    margin-top: $spacing-40;
  }

  @include lg {
    padding: 0;
  }
}

.labResults {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  margin-top: $spacing-24;

  @include md {
    width: 550px;
    margin: 0 auto;
    gap: $spacing-24;
    margin-top: $spacing-40;
  }
}

.actions {
  margin-top: $spacing-24;
  padding: 0 $spacing-24;

  @include md {
    margin-top: $spacing-40;
  }

  @include lg {
    padding: 0;
  }
}

.recommendedActions {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  margin-top: $spacing-24;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
    gap: $spacing-24;
  }
}

.downloadMobileLine {
  border-bottom: 1px dashed $color-grey;
}

.sidebarWrapper {
  display: none;

  @include lg {
    display: block;
    width: 300px;
  }
}

.sidebar {
  display: none;
  margin-left: $spacing-40;

  & svg {
    min-width: $spacing-24;
    min-height: $spacing-24;
  }

  @include lg {
    display: block;
    width: fit-content;
    position: fixed;
    top: 218px;
  }
}

.navigationDesktop {
  margin-bottom: $spacing-80;
}

.documents {
  color: $color-blacky;
  margin-bottom: $spacing-24;
  text-transform: uppercase;
}

.documentsWrapperMobile {
  @include md {
    padding: 0 $spacing-24;
  }

  @include lg {
    display: none;
  }
}
