@import 'src/scss/base';

.my-health-checkups {
  background: $color-grey-light;
  padding: $spacing-24 $spacing-24 $spacing-64 $spacing-24;
  max-width: 740px;
  margin: 0 auto;

  @include md {
    padding: $spacing-32 $spacing-24;
  }

  @include lg {
    padding: $spacing-40 0;
  }
}

.my-health-checkups--no-content {
  max-width: 740px;
  margin: 0 auto;
  height: calc(100vh - $header-and-navbar-sum-mobile);

  @include md {
    padding: $spacing-32 $spacing-24;
    height: calc(100vh - $header-and-navbar-sum-tablet);
  }

  @include lg {
    padding: $spacing-40 0;
    height: calc(100vh - $header-height-desktop);
  }
}
