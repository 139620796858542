@import 'src/scss/base';

.wrapper {
  border-radius: $border-radius;
  padding: 0 $spacing-24;
  margin-top: $spacing-24;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
    padding: 0;
  }
}

.content {
  padding: $spacing-16 $spacing-24;
  color: $color-white;
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  z-index: 1;

  @include md {
    padding: $spacing-24;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.tag {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  background-color: $color-grey-light;
  border-radius: $border-radius-32;
  width: fit-content;
  margin-bottom: $spacing-16;
}

.successIcon {
  margin-left: $spacing-4;
}

.tagName {
  font-size: 12px;
  line-height: normal;
  padding: $spacing-4 $spacing-8 $spacing-4 0;
  color: $color-blacky;
}

.title {
  margin-bottom: $spacing-8;
}

.description {
  margin-bottom: $spacing-16;

  @include md {
    margin-bottom: $spacing-24;
  }
}

.link {
  display: block;
  text-decoration: none;
  width: fit-content;
  padding: $spacing-8 $spacing-16;
  border-radius: $border-radius-32;
  background-color: transparent;
  border: 1px solid $color-white;
  color: $color-white;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}
