@import 'src/scss/base';

.accordionSection {
  color: $color-blacky;
  padding-top: $spacing-16;
  position: relative;

  @include md {
    padding-top: $spacing-24;
  }
}

.accordionHeader {
  display: flex;
  position: relative;
}

.accordionContentWrapper {
  position: relative;
  cursor: pointer;
  flex: 1;
  border-radius: $spacing-8;
  background-color: $color-white;
  box-shadow: $card-box-shadow;
}

.gradientWrapper {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 1em;
}

.gradientWrapper:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: $color-gradient;
}

.accordionTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-16 $spacing-24;
}

.accordionTitle {
  margin-bottom: $spacing-4;
  color: $color-success;
  width: fit-content;
}

.arrowDown {
  transition: all 0.5s ease;
}

.arrowUp {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}

.accordionContent {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
