@import 'src/scss/base';

.recommended-actions-timing {
  color: $color-grey-darker;
  display: flex;
  align-items: center;
  gap: $spacing-24;
  padding: $spacing-16 0 $spacing-8 0;
}

.recommended-actions-timing__circle {
  margin-left: $spacing-8;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $color-grey;
  position: relative;
}

.recommended-actions-timing__line {
  position: absolute;
  top: 0;
  left: 3px;
  width: 1px;
  height: $spacing-80;
  background-color: $color-grey;
}

.recommended-actions-timing__text-wrapper {
  display: flex;
  gap: $spacing-16;
  align-items: center;
}

.recommended-actions-timing__text {
  line-height: normal;
}

.recommended-actions-timing__number {
  background: $color-success;
  padding: 0 6px;
  border-radius: 6px;
  color: $color-white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
