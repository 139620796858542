@import 'src/scss/base';

.wrapper {
  color: $color-blacky;
  background: $color-white;
  padding: $spacing-16;
  border-radius: $border-radius;

  ul {
    padding: 0;
    padding-left: $spacing-16;
    margin-top: $spacing-4;
  }

  @include md {
    padding: $spacing-24;
  }

  @include lg {
    padding: $spacing-32;
  }
}

.imageWrapper {
  margin-top: $spacing-24;
  display: flex;
  align-items: center;
  gap: $spacing-8;

  @include lg {
    gap: $spacing-16;
  }
}
