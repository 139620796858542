@import 'src/scss/base';

.home {
  position: relative;
  min-height: 100vh;
  overflow: auto;
  background-color: $color-purple;
  overflow-x: hidden;
}

.home__elipse-wrapper {
  position: absolute;
  left: 50%;
}

.home__elipse {
  width: px2rem(1200);
  height: px2rem(500);
  border-radius: 50%;
  background-color: $color-white;
  position: relative;
  z-index: 1;
  left: -50%;
  top: px2rem(-250);

  @include xs-only {
    width: px2rem(1000);
  }

  @include md {
    width: px2rem(1600);
    height: px2rem(600);
    top: px2rem(-300);
  }

  @include lg {
    width: px2rem(2400);
    height: px2rem(1000);
    top: px2rem(-620);
  }

  @include xl {
    width: px2rem(3400);
    height: px2rem(1500);
    top: px2rem(-1120);
  }
}

.home__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home__loader-wrapper {
  display: flex;
  justify-content: center;
  padding-top: px2rem(100);
}

.home__loader {
  height: 100vh;
  background: $color-grey-light;
}

.home__loader::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: $color-secondary;
  border-radius: 50%;
  content: '';
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.home__background-wrapper {
  height: 100vh;

  .background__background {
    overflow-y: hidden;
  }
}
