@import 'src/scss/base';

.booking-iframe-container {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  margin-top: $spacing-8;

  @include lg {
    margin-top: $spacing-16;
  }
}

.booking-iframe {
  position: relative;

  &--loading-iframe {
    height: 100vh;

    .booking-iframe__loading-overlay {
      background: transparent;
      box-shadow: none;
    }

    .booking-iframe__loader {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border: 5px solid #cfd0d1;
      border-top-color: $color-secondary;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }
}

.booking-iframe__loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-white;
  z-index: 10;
  border-radius: $border-radius-8;

  @include lg {
    border-radius: $border-radius-24;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  }
}

.booking-iframe__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 5px solid #cfd0d1;
  border-top-color: $color-secondary;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

.booking-iframe__error-message {
  text-align: center;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}