@import 'src/scss/base';

.wrapper {
  background: $color-white;
  color: $color-blacky;
  padding: $spacing-24;
  border-radius: $spacing-16;
  box-shadow: $card-box-shadow;
}

.wrapperWithPreviousResults {
  padding-bottom: 0;
}

.titleAndResultWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-8;
}

.title {
  position: relative;
  top: -2px;
  display: inline;
  color: $color-blacky;
}

.icon {
  @include button-default;
  display: inline;
  position: relative;
  top: -5px;
  margin-left: $spacing-4;
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.resultTextWrapper {
  margin-left: $spacing-8;
  border-radius: 5px;
}

.resultText {
  color: $color-white;
  padding: $spacing-4 $spacing-8;
}

.text {
  margin-bottom: $spacing-16;
}

.progressBarWithResults {
  margin: $spacing-40 0 $spacing-40 0;
}

.progressBarWithoutResults {
  margin: $spacing-40 0 $spacing-16 0;
}

.buttonWrapper {
  @include button-default;
  padding: $spacing-16 0;
  border-top: 1px dashed $color-grey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonTextWrapper {
  display: flex;
  gap: $spacing-4;
}

.buttonText {
  color: $color-grey-darker;
}
