@import 'src/scss/base';

.wrapper {
  display: flex;
  align-items: flex-start;
  gap: $spacing-8;
}

.wrapper svg {
  flex-shrink: 0;
}

.link {
  text-decoration: none;
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  cursor: pointer;
}
