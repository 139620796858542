@import 'src/scss/base';

.learn-more-post {
  background: $color-grey-light;
  padding-bottom: $spacing-64;
  max-width: 740px;
  margin: 0 auto;
}

.learn-more-post__error {
  padding: $spacing-24 $spacing-24 0 $spacing-24;
  height: calc(100vh - $header-and-navbar-sum-mobile);
  text-align: center;
  color: $color-blacky;

  @include md {
    padding: $spacing-32 $spacing-24 0 $spacing-24;
  }

  @include lg {
    padding: $spacing-40 0 0 0;
    height: calc(100vh - $header-height-desktop);
  }
}
