@import 'src/scss/base';

.link {
  color: $color-blacky;
  @include button-default;
  position: relative;

  @include lg {
    width: fit-content;
  }
}

.link--active {
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  p {
    font-weight: 500;
  }
}

.link__text {
  padding: $spacing-8 0;
}
