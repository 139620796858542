@import 'src/scss/base';

.checkup-accordion-add-to-cart-button {
  padding: 10px $spacing-24;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: $color-gradient;
  color: $color-white;
  border-radius: $border-radius-32;
  text-decoration: none;
}

.checkup-accordion-add-to-cart-button--disabled {
  background: $color-grey-light;
  color: $color-grey-darker;
  cursor: not-allowed;
}
