@import 'src/scss/base';

.recommended-actions-checkbox {
  width: 100%;
  cursor: pointer;
  background-color: $color-white;
  appearance: none;
  border: 2px solid $color-grey;
  width: $spacing-16;
  min-width: $spacing-16;
  height: $spacing-16;
  min-height: $spacing-16;
  margin: 0;
  margin-right: $spacing-8;
  border-radius: 4px;
  margin-top: $spacing-32;

  @include sm {
    width: $spacing-24;
    min-width: $spacing-24;
    height: $spacing-24;
    min-height: $spacing-24;
    margin-right: $spacing-16;
  }

  &:checked {
    border: none;
    background-image: url('../../../../assets/svg/checkbox-success.svg');
    background-position: center;
  }
}
