@import 'src/scss/base';

.wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: $color-black;
}

.wrapperWhite {
  color: $color-white;
}

.languageWrapper {
  @include body-2;
  width: $spacing-64;
  height: 100%;
  padding: 6px $spacing-8 6px 12px;
  border: 1px solid $color-white;
  border-radius: $spacing-8;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.languageWrapperWhite {
  border: 1px solid $color-white;

  & svg,
  path {
    fill: $color-white;
  }
}

.arrowDown {
  margin-left: $spacing-4;
  transition: all 0.3s ease;
}

.arrowUp {
  transform: rotate(-180deg);
  transition: all 0.3s ease;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: $spacing-8;
  background-color: $color-white;
  box-shadow: 0 $spacing-8 $spacing-16 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownItem {
  @include body-2;
  padding: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.dropdownItemColorful {
  color: $color-additional-2;
}
