@import 'src/scss/base';

.wrapper {
  display: flex;
  align-items: flex-start;
  gap: $spacing-8;
}

.wrapper svg {
  flex-shrink: 0;
}
