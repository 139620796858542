// Colors:
$color-white: #ffffff;
$color-dirty-white: #f2f2f2;
$color-black: #333333;

$color-purple: #554494;
$color-purple-dark: #3f276c;
$color-light-grey: #efefef;
$color-light-grey-2: #f8f8f8;
$color-light-grey-3: #e0e0e0;
$color-grey: #999999;
$color-dark-grey: #666666;
$color-dark-grey-2: #bdbdbd;
$color-dark-grey-3: #4f4f4f;
$color-border-grey: #f3f3f3;
$color-disabled: #cccccc;
$color-red: #ff0004;
$color-orange: #ff6145;
$color-dark-orange: #f26149;
$color-yellow: #f8c495;
$color-light-blue: #c7d1f6;
$color-green: #0aae21;
$color-dark-green: #1f7359;
$color-gradient: linear-gradient(
  90deg,
  rgba(85, 68, 148, 1) 0%,
  rgba(255, 97, 69, 1) 100%
);

// new design
$color-primary: #ff6145;
$color-primary-light: #ffefe7;
$color-secondary: #564494;
$color-secondary-light: #ccccff;
$color-blacky: #322c36;
$color-grey-darker: #6f6b72;
$color-grey-dark: #98959b;
$color-grey: #d0d1dd;
$color-grey-light: #f1f1f1;
$color-grey-lighter: #fcfcfc;
// This is special case, do not use this way of naming colors
$color-grey-card: #e5e5e5;
$color-warning: #b3261e;
$color-warning-surface: #e78280;
$color-info: #ff6145;
$color-success: #7c9179;
$color-success-surface: #c9ddc6;
$color-additional-1: #894d7d;
$color-additional-2: #c8585f;
$color-grey-disabled: #1c1b1f1f;
$color-secondary-medium: #6750a4;
$color-grey-medium: #1d192b;
$button-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
  0px 1px 2px 0px rgba(0, 0, 0, 0.3);
$input-box-shadow: 0px 0px 2px 1px rgba(159, 138, 229, 0.35);
$color-red-gradient: linear-gradient(0deg, #c8585f 0%, #c8585f 100%), #c8585f;
$card-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$color-semi-transparent-gradient-white: linear-gradient(
  261deg,
  #b7b7b766 0.97%,
  #86868657 94.26%
);

// Spacings
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-72: 72px;
$spacing-80: 80px;
$spacing-96: 96px;
$spacing-128: 128px;
$spacing-160: 160px;
$spacing-192: 192px;

//border radius
$border-radius-8: 8px;
$border-radius: 16px;
$border-radius-24: 24px;
$border-radius-32: 32px;

// header height
$header-height-mobile: 82px;
$header-height-desktop: 98px;

// sum of header height and navbar bottom height
$header-and-navbar-sum-mobile: 178px;
$header-and-navbar-sum-tablet: 162px;
