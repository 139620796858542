@import 'src/scss/base';

.checkup-accordion {
  position: relative;
  border-radius: $border-radius;
  background-color: $color-white;
  color: $color-blacky;
  box-shadow: $card-box-shadow;
  overflow: hidden;
}

.checkup-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-24;

  @include lg {
    cursor: pointer;
  }
}

.checkup-accordion__header-content {
  display: flex;
  align-items: flex-start;
  background: $color-white;
  width: 100%;
}

.checkup-accordion__header-number {
  margin-right: $spacing-16;
  padding: $spacing-8 $spacing-16;
  border-radius: $border-radius-32;
  background-color: $color-light-grey;
}

.checkup-accordion__header-text-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $spacing-4;
}

.checkup-accordion__header-title-and-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkup-accordion__header-title {
  font-weight: 500;

  @include md {
    font-size: 20px;
  }
}

.checkup-accordion__header-subtitle {
  color: #798191;
}

.checkup-accordion__arrow-down {
  transition: all 0.5s ease;
}

.checkup-accordion__arrow-up {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}
