@import 'src/scss/base';

.lab-results-accordion {
  position: relative;
  cursor: pointer;
  flex: 1;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $card-box-shadow;
}

.lab-results-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-24;
  background-color: $color-light-grey-2;
}

.lab-results-accordion__header-wrapper {
  display: flex;
  align-items: center;
}

.lab-results-accordion__description {
  margin-top: $spacing-8;
}

.lab-results-accordion__info-icon {
  @include button-default;
}

.lab-results-accordion__arrow-down {
  transition: all 0.5s ease;
}

.lab-results-accordion__arrow-up {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}

.lab-results-accordion__accordion-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
